<template>
  <v-container
    class="fill-height"
    fluid
  >
    <v-row
      justify="center"
    >
      <v-col cols="12">
        <material-card
          id="materialCardTeste"
          color="green"
        >
          <v-col
            id="materialCardHeader"
            slot="header"
          >
            <v-tabs
              v-model="tab"
              color="transparent"
              slider-color="yellow"
            >
              <span
                class="subheading font-weight-light mr-3"
                style="align-self: center"
              >Pessoa</span>
              <v-tab
                v-for="item in tabCabecalhosValendo"
                :key="item.texto"
                :href="item.link"
                class="mr-3"
              >
                {{ item.texto }}
                <v-icon
                  v-if="item.icone"
                  class="mr-2"
                >
                  {{ item.icone }}
                </v-icon>
              </v-tab>
            </v-tabs>
          </v-col>
          <v-form>
            <v-tabs-items v-model="tab">
              <v-tab-item :value="'tab-dados-pessoais'">
                <v-container class="py-0">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="objEditar.nome"
                        :rules="[required]"
                        required
                        prepend-icon="mdi-account-edit"
                        label="Nome"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="objEditar.apelido"
                        prepend-icon="mdi-account-edit"
                        label="Apelido"
                      />
                    </v-col>
                    <v-col
                      cols="5"
                      class="hidden-md-and-up text-left"
                    >
                      <span>Tipo de Pessoa</span>
                    </v-col>
                    <v-col
                      cols="7"
                      class="hidden-md-and-up text-right"
                    >
                      <v-radio-group
                        v-model="objEditar.tipoPessoa"
                        :mandatory="true"
                        row
                      >
                        <v-radio
                          label="Física"
                          value="F"
                        />
                        <v-radio
                          label="Jurídica"
                          value="J"
                        />
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="hidden-sm-and-down"
                    >
                      <span>Tipo de Pessoa</span>
                      <v-radio-group
                        v-model="objEditar.tipoPessoa"
                        :mandatory="true"
                        row
                      >
                        <v-radio
                          label="Física"
                          value="F"
                        />
                        <v-radio
                          label="Jurídica"
                          value="J"
                        />
                      </v-radio-group>
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="objEditar.bloco"
                        prepend-icon="mdi-business"
                        label="Bloco"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="objEditar.apartamento"
                        label="Apartamento"
                      />
                    </v-col>
                    <v-col
                      v-if="objEditar.tipoPessoa === 'J'"
                      cols="12"
                      sm="8"
                    >
                      <v-text-field
                        v-model="objEditar.razaoSocial"
                        prepend-icon="mdi-factory"
                        label="Razão Social"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        v-model="objEditar.cpfOuCnpj"
                        :label="objEditar.tipoPessoa === 'F'? 'CPF' : 'CNPJ'"
                        prepend-icon="mdi-account-card-details"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="5"
                    >
                      <v-text-field
                        v-model="objEditar.rg"
                        prepend-icon="mdi-account-card-details"
                        label="RG"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                    >
                      <v-text-field
                        v-model="objEditar.orgaoEmissor"
                        prepend-icon="mdi-account-card-details"
                        label="Orgão Emissor"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <v-text-field
                        v-model="objEditar.dataRG"
                        prepend-icon="mdi-account-card-details"
                        label="Data Emissão"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="objEditar.nomePai"
                        prepend-icon="mdi-account-edit"
                        label="Nome do Pai"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="objEditar.nomeMae"
                        prepend-icon="mdi-account-edit"
                        label="Nome da Mãe"
                      />
                    </v-col>
                    <v-alert
                      v-model="alertValidacao"
                      dismissible
                      type="error"
                      transition="scale-transition"
                    >
                      {{ error }}
                    </v-alert>
                  </v-row>
                </v-container>
              </v-tab-item>
              <!-- fim dados pessoais -->
              <!-- inicio endereço -->
              <v-tab-item :value="'tab-enderecos'">
                <!-- <endereco :idPessoa="objEditar.id">

                                </endereco> -->
                olá mundo
              </v-tab-item>
              <!-- fim endereço -->
              <!-- inicio veiculos -->
              <v-tab-item :value="'tab-veiculos'">
                <!-- <veiculo :idPessoa="objEditar.id">

                                </veiculo> -->
                olá mundo
              </v-tab-item>
              <!-- fim veiculos -->
              <!-- inicio animais -->
              <v-tab-item :value="'tab-animais'">
                <!-- <animal :idPessoa="objEditar.id">

                                </animal> -->
                olá mundo
              </v-tab-item>
              <!-- fim animais -->
            </v-tabs-items>
          </v-form>
          <template
            v-if="tab === 'tab-dados-pessoais'"
            slot="actions"
          >
            <v-btn
              v-if="isEditting"
              color="error"
              class="hidden-sm-and-down"
              @click="deleteEvent()"
            >
              delete
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              color="tertiary"
              class="hidden-sm-and-down"
              @click="closeDialog()"
            >
              Cancelar
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
            <v-btn
              class="hidden-sm-and-down"
              color="info"
              @click="saveEvent()"
            >
              Salvar
              <v-icon dark>
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!isEditting"
              class="hidden-sm-and-down"
              color="success"
              @click="saveContinueEvent()"
            >
              Salvar e Continuar
              <v-icon dark>
                mdi-content-save
              </v-icon>
            </v-btn>
            <v-menu
              offset-y
              class="hidden-md-and-up"
            >
              <v-btn slot="activator">
                <v-icon dard>
                  mdi-gesture-tap
                </v-icon>
                Ações
              </v-btn>
              <v-list>
                <slot name="botoesAdicionaisTelaPequenaAntesSalvar" />
                <v-list-item
                  v-if="mostraBotaoSalvarContinuar"
                  @click="saveContinueEvent()"
                >
                  <v-list-item-action>
                    <v-icon color="info">
                      mdi-content-save
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Salvar e Continuar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="saveEvent()">
                  <v-list-item-action>
                    <v-icon color="primary">
                      mdi-content-save
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Salvar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="closeDialog()">
                  <v-list-item-action>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Cancelar</v-list-item-title>
                </v-list-item>
                <slot name="botoesAdicionaisTelaPequenaDepoisCancelar" />
                <v-divider v-if="isEditting" />
                <v-divider v-if="isEditting" />
                <slot name="botoesAdicionaisTelaPequenaAntesDeletar" />
                <v-list-item
                  v-if="isEditting"
                  @click="deleteEvent()"
                >
                  <v-list-item-action>
                    <v-icon color="error">
                      mdi-close
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item>
                <slot name="botoesAdicionaisTelaPequenaDepoisDeletar" />
              </v-list>
            </v-menu>
          </template>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import Servico from '@/services/PessoasService'

  export default {
    data: () => ({
      objPadrao: {
        nome: '',
        apelido: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        razaoSocial: '',
        rg: '',
        orgaoEmissor: '',
        dataRG: null,
        nomePai: '',
        nomeMae: '',
        bloco: 0,
        apartamento: 0,
        id: 0
      },
      objEditar: {
        nome: '',
        apelido: '',
        tipoPessoa: 'F',
        cpfOuCnpj: '',
        razaoSocial: '',
        rg: '',
        orgaoEmissor: '',
        dataRG: null,
        nomePai: '',
        nomeMae: '',
        bloco: 0,
        apartamento: 0,
        id: 0
      },
      required: value => !!value || 'Campo Obrigatório',
      isEditting: true,
      error: null,
      validForm: false,
      alertValidacao: false,
      mostraBotaoSalvarContinuar: false,
      tab: null,
      tabCabecalhos: [
        {
          texto: 'Dados Pessoais',
          link: '#tab-dados-pessoais',
          mostraCabecalho: true
        },
        {
          texto: 'Endereços',
          link: '#tab-enderecos',
          mostraCabecalho: false
        },
        {
          texto: 'Veículos',
          link: '#tab-veiculos',
          mostraCabecalho: false
        },
        {
          texto: 'Animais',
          link: '#tab-animais',
          mostraCabecalho: false
        }
      ]
    }),
    computed: {
      tabCabecalhosValendo () {
        var tabValendo = this.tabCabecalhos.filter((tab) => {
          return tab.mostraCabecalho
        })
        return tabValendo
      }
    },
    created () {
      let id = this.$route.params.id
      if (id) {
        this.editar(id)
      } else {
        this.isEditting = false
        this.objEditar = this.objPadrao
      }
    },
    methods: {
      deleteEventHandler (item) {
        this.$root.$emit('mostra_dialogo_pergunta', {
          dialogoTitulo: 'Atenção',
          dialogoDescricao: 'Tens certeza que deseja remover esta pessoa?',
          funcaoRetorno: this.remover
        })
      },
      remover () {
        const registro = this.objEditar
        try {
          Servico.delete(registro)
            .then(response => {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Pessoa removida com sucesso'
              })
              this.buscar()
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao remover um condomínio:\n ->' + error
          })
        }
      },
      deleteEvent () {
        this.deleteEventHandler()
      },
      saveEvent () {
        this.saveEventHandler()
      },
      saveContinueEvent () {
        this.inserir(this.objEditar, true)
      },
      closeDialog () {
        this.buscar()
      },
      saveEventHandler () {
        if (this.isEditting) {
          this.atualizar(this.objEditar)
        } else {
          this.inserir(this.objEditar)
        }
      },
      atualizar (registro) {
        try {
          Servico.update(registro)
            .then(response => {
              if (!response) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
                })
              } else if (response.error) {
                this.$root.$emit('mostra_dialogo_erro', {
                  dialogoDescricao: '' + response.error
                })
              } else {
                this.$root.$emit('mostra_dialogo_info', {
                  dialogoTitulo: 'Sucesso',
                  dialogoDescricao: 'Pessoa salvo com sucesso'
                })
                this.buscar()
              }
            })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Não foi possível concluir a atualização, tente mais tarde\n' + error
          })
        }
      },
      buscar () {
        this.$router.push('/pessoas')
      },
      inserir (pessoa, continuar) {
        try {
          Servico.insert(pessoa).then(response => {
            if (!response) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: 'Deu algum erro,\nResposta do Servidor: null '
              })
            } else if (response.error) {
              this.$root.$emit('mostra_dialogo_erro', {
                dialogoDescricao: '' + response.error
              })
            } else {
              this.$root.$emit('mostra_dialogo_info', {
                dialogoTitulo: 'Sucesso',
                dialogoDescricao: 'Pessoa salva com sucesso'
              })
              if (continuar) {
                this.editar(response.data.id)
              }
            }
          })
        } catch (error) {
          this.$root.$emit('mostra_dialogo_erro', {
            dialogoDescricao: 'Ocorreu um erro ao inserir um condomínio:\n ->' + error
          })
        }
      },
      editar (id) {
        Servico.findById({ id })
          .then(registro => {
            if (registro.id) {
              this.objEditar = registro
              this.isEditting = true
            } else if (registro.data.id) {
              this.objEditar = registro.data
              this.isEditting = true
              this.tabCabecalhos.forEach((cadaTab) => {
                cadaTab.mostraCabecalho = true
              })
            } else {
              this.isEditting = false
            }
          })
          .catch(() => {
            this.isEditting = false
          })
      }
    }
  }
</script>
